import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../../store/actions/index';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  close = () =>
    this.setState({
      isOpen: false
    });

  render() {
    const { hasPrograms, isAuthenticated, tvp } = this.props;

    const isTvpTuw =
      window.location.hostname === 'tvp-tuwpzuw.uspartner.pl' &&
      new Date('2022-10-24').getTime() > new Date().getTime();

    let profileMenu = null;

    const generateMenu = this.props.menu.map((item) => {
      if (item && item.displayName === 'Profile') {
        profileMenu = item.nestedElements.map((nestedItem) => (
          <Link
            onClick={this.close}
            to={`/${nestedItem.pageId}`}
            key={nestedItem.nodeId}>
            <DropdownItem tag="div" className="userDropdownItem">
              <div className="userDropIcon">
                <FontAwesomeIcon
                  fixedWidth
                  icon={nestedItem.materialIcon || 'dot-circle'}
                />
              </div>
              <div className="userName">{nestedItem.displayName}</div>
            </DropdownItem>
          </Link>
        ));
        return null;
      }
      if (item.nestedElements) {
        return (
          <UncontrolledDropdown key={item.nodeId} nav inNavbar>
            <DropdownToggle nav caret>
              {item.displayName}
            </DropdownToggle>
            <DropdownMenu>
              {item.nestedElements.map((nestedItem) => (
                <Link
                  onClick={this.close}
                  to={`/${nestedItem.pageId}`}
                  key={nestedItem.nodeId}>
                  <DropdownItem>{nestedItem.displayName}</DropdownItem>
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else if (item.pageId) {
        return (
          <NavItem key={item.nodeId}>
            <NavLink
              onClick={this.close}
              tag={RouterNavLink}
              to={`/${item.pageId}`}>
              {item.displayName}
            </NavLink>
          </NavItem>
        );
      }
      return null;
    });
    // const generateMenu = null;

    return (
      <div style={{ backgroundColor: '#003c7d' }} className="fixedTop">
        <Navbar dark expand="md" className="fixedWidth">
          <NavbarBrand tag={Link} onClick={this.close} to="/">
            {(!isAuthenticated || tvp) && (
              <img
                style={{ height: '50px', padding: '5px 5px 5px 0' }}
                src={process.env.PUBLIC_URL + '/tvp/tvp-logo.png'}
                alt="TVP logo"
              />
            )}
            {isAuthenticated && !tvp && (
              <img
                style={{
                  height: '50px',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  marginRight: '0.5rem'
                }}
                src="/public-assets/usp.png"
                alt="USP  logo"
              />
            )}
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              {(!isAuthenticated || tvp) && (
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Ubezpieczenia zdrowotne
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link onClick={this.close} to="/pracownicy">
                        <DropdownItem>TVP Pracownicy</DropdownItem>
                      </Link>
                      <Link onClick={this.close} to="/wspolpracownicy">
                        <DropdownItem>TVP Współpracownicy</DropdownItem>
                      </Link>
                      <Link onClick={this.close} to="/senior">
                        <DropdownItem>Senior</DropdownItem>
                      </Link>
                      <Link onClick={this.close} to="/dorosleDziecko">
                        <DropdownItem>Dorosle Dziecko</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Dodatkowe ubezpieczenia
                    </DropdownToggle>
                    <DropdownMenu>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/pracownicy/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>Opieka szpitalna</DropdownItem>
                      </NavLink>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/zagraniczne/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>Podróże zagraniczne</DropdownItem>
                      </NavLink>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/assistance/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>Moto Assistance</DropdownItem>
                      </NavLink>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/nnw/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>NNW szkolne</DropdownItem>
                      </NavLink>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/majatkowe/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>Majątkowe</DropdownItem>
                      </NavLink>
                      <NavLink
                        onClick={this.close}
                        href="https://tvpubezpieczenia.uspartner.pl/likwidacja/opis/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownItem>
                          Szkody komunikacyjne OC/AC Pomoc
                        </DropdownItem>
                      </NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              )}

              {generateMenu}
            </Nav>
            <Nav className="ml-auto" navbar>
              {this.props.isAuthenticated && (
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <FontAwesomeIcon fixedWidth icon="user-circle" />
                      <span className="navbarUsername">
                        {this.props.username}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right className="userDropdown">
                      <div className="userDropdownItem profile">
                        <div className="userDropIcon">
                          <FontAwesomeIcon fixedWidth icon="user-circle" />
                        </div>
                        <div className="userName">{this.props.username}</div>
                      </div>
                      {hasPrograms && (
                        <Link onClick={this.close} to="/changeprogram/">
                          <DropdownItem tag="div" className="userDropdownItem">
                            <div className="userDropIcon">
                              <FontAwesomeIcon fixedWidth icon="exchange-alt" />
                            </div>
                            <div className="userName">Zmień program</div>
                          </DropdownItem>
                        </Link>
                      )}
                      {profileMenu}
                      {/* <Link onClick={this.close} to="/change-password/">
                        <DropdownItem
                          tag="div"
                          onClick={() => {
                            this.close();
                          }}
                          className="userDropdownItem">
                          <div className="userDropIcon">
                            <FontAwesomeIcon fixedWidth icon="key" />
                          </div>
                          <div className="userName">Zmień hasło</div>
                        </DropdownItem>
                      </Link> */}
                      <DropdownItem
                        tag="div"
                        onClick={() => {
                          this.close();
                          this.props.logout();
                        }}
                        className="userDropdownItem">
                        <div className="userDropIcon">
                          <FontAwesomeIcon fixedWidth icon="sign-out-alt" />
                        </div>
                        <div className="userName">Wyloguj się</div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              )}
              {!isTvpTuw && !this.props.isAuthenticated && (
                <React.Fragment>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/login/"
                      className="loginRegister">
                      <FontAwesomeIcon fixedWidth icon="sign-in-alt" />
                      Logowanie
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={RouterNavLink}
                      onClick={this.close}
                      to="/register/"
                      className="loginRegister">
                      <FontAwesomeIcon fixedWidth icon="user-friends" />
                      Rejestracja
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const programs = state.auth.programs || [];
  return {
    isAuthenticated: state.auth.token !== null,

    username: state.auth.user.name,
    tvp: state.auth.user.programId === '7651f192-5247-4a85-9ed3-5e510bf96167',
    hasPrograms: programs.length > 1 ? true : false,
    menu: state.menu.data && state.menu.data.menu ? state.menu.data.menu : []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
