import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import classes from './PasswordRecoveryForm.module.scss';
import InputField from '../../StaticFormComponents/TextField';
import { replace } from 'connected-react-router';

import { Button, Form, Alert, Spinner } from 'reactstrap';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { required, email } from 'redux-form-validators';

let PasswordRecoveryForm = (props) => {
  const { handleSubmit, pristine, submitting, error, onSubmit } = props;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmitForm = async (values) => {
    const incomingValues = { ...values };
    const recaptchaToken = await executeRecaptcha('passwordReset');
    return onSubmit({ ...incomingValues, recaptchaToken });
  };

  return (
    <Form className={classes.form} onSubmit={handleSubmit(onSubmitForm)}>
      <h4>Odzyskiwanie hasła</h4>
      {error && <Alert color="danger">{error}</Alert>}

      <Field
        name="email"
        label="Email"
        component={InputField}
        placeholder="Email"
        type="text"
        autocomplete="nope"
        className="form-control"
        validate={[
          required({ msg: 'Pole wymagane' }),
          email({ msg: 'Wymagany jest poprawny email.' })
        ]}
      />
      <Button
        type="submit"
        color="success"
        className="submitButton"
        disabled={submitting || pristine}>
        {!submitting && 'Odzyskaj hasło'}
        {submitting && <Spinner size="sm" />}
      </Button>
    </Form>
  );
};

PasswordRecoveryForm = reduxForm({
  form: 'passwordRecovery'
})(PasswordRecoveryForm);

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values) => {
      return new Promise((resolve, reject) => {
        dispatch(actions.passwordRecovery(values))
          .then((successAction) => {
            resolve(true);
            dispatch(
              replace(
                `/password-recovery-set?requestSent=true`
                // `/password-recovery-set?requestSent=true&email=${values.email}`
              )
            );
          })
          .catch((errorOrAbortAction) => {
            let errorMessage = errorOrAbortAction.error.response.data;

            if (
              errorMessage &&
              !(
                typeof errorMessage === 'string' ||
                errorMessage instanceof String
              ) &&
              errorMessage.message
            ) {
              errorMessage = errorMessage.message;
            }
            if (!errorMessage) {
              errorMessage =
                'Nie znaleziono uzytkownika z podanym adresem email.';
            }
            reject(
              new SubmissionError({
                _error: errorMessage
              })
            );
          });
      });
    }
  };
};

export default connect(null, mapDispatchToProps)(PasswordRecoveryForm);
